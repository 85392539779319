* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans Expanded', sans-serif;
    
  }
  
  body{
    margin: 0;
    padding: 0;
  }
  
  /* font-family: 'Open Sans', sans-serif;
  font-family: 'Patua One', cursive;
  font-family: 'Ubuntu', sans-serif; */ 